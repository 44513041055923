import { Link, Page, Text, View } from '@react-pdf/renderer'
import {
  PdfBrokerProfile,
  PdfNotes,
  PdfPreparedFor,
  tw,
} from '~/components/pdfs'
import type { Company, Listing, ReportPdfOptions, User } from '~/models'
import { cn, fmtAddress } from '~/utils'

export default function ReportPdfTitlePage({
  title,
  user,
  company,
  listings,
  pdfOptions,
  orientation,
}: {
  title: string
  user: User
  company?: Company
  listings: Listing[]
  pdfOptions: ReportPdfOptions
  orientation: 'portrait' | 'landscape'
}) {
  const renderContents = () => {
    if (!pdfOptions.showContents) return null
    return (
      <View style={tw('mt-8')}>
        <Text style={tw('font-heading mb-4 text-lg leading-none')}>
          Table of Contents
        </Text>
        <View style={tw('flex flex-col gap-1')}>
          {listings.map((listing, index) => {
            return (
              <View key={listing._id} style={tw('flex flex-row')}>
                <Text
                  style={tw(
                    cn('text-base', listings.length > 99 ? 'w-8' : 'w-6')
                  )}>
                  {index + 1}.
                </Text>
                <Link
                  style={tw('text-base text-black')}
                  src={`#${listing.tid}`}>
                  {fmtAddress(listing.property, true)}
                </Link>
              </View>
            )
          })}
        </View>
      </View>
    )
  }

  return (
    <Page size="LETTER" style={tw('font-sans')} orientation={orientation}>
      <View style={tw('flex flex-row')}>
        <View style={tw(cn('p-8', pdfOptions.showBrokerProfile && 'w-2/3'))}>
          <Text style={tw('font-heading mb-2 text-3xl leading-tight')}>
            {title}
          </Text>
          <PdfPreparedFor preparedFor={pdfOptions.preparedFor} />
          <PdfNotes notes={pdfOptions.notes} />
          {renderContents()}
        </View>
        {pdfOptions.showBrokerProfile && (
          <PdfBrokerProfile
            orientation={orientation}
            user={user}
            company={company}
          />
        )}
      </View>
    </Page>
  )
}
