import { Image, Link, Page, Text, View } from '@react-pdf/renderer'
import { tw } from '~/components/pdfs'
import type { Organization, ReportPdfOptions } from '~/models'
import { transformImageUrl } from '~/utils'

export default function PdfDisclaimerPage({
  organization,
  pdfOptions,
  orientation,
}: {
  organization: Organization
  pdfOptions: ReportPdfOptions
  orientation: 'portrait' | 'landscape'
}) {
  return (
    <Page
      size="LETTER"
      style={tw('p-8 font-sans text-sm leading-relaxed')}
      orientation={orientation}>
      <View style={tw('mb-8 flex flex-row justify-between')}>
        {organization.meta?.logo ? (
          <View>
            <Image
              style={tw('h-14 w-auto')}
              src={transformImageUrl(organization.meta.logo!.url!)}
            />
          </View>
        ) : (
          <View />
        )}
        <View>
          <Image
            style={tw('h-14 w-auto')}
            src={`https://${organization.slug}.resimplifi.com/resimplifi-logo.png`}
          />
        </View>
      </View>
      <Text style={tw('mb-6')}>
        The information provided in this report by {organization.name} is
        intended for informational purposes only. {organization.name} does not
        warrant or represent that the information provided is complete,
        accurate, or up-to-date. Users of this report are advised to conduct
        their own independent verification and assessment of the information
        before making any decisions or taking any actions based on it.{' '}
        {organization.name} shall not be liable for any errors, omissions, or
        inaccuracies in the information provided, nor for any damages or losses
        arising from the use of or reliance on the information contained herein.
      </Text>
      <Text style={tw('mb-6')}>
        Resimplifi strictly prohibits any user of the Resimplifi application to
        post any images or other materials that are not the property of the user
        per the Resimplifi{' '}
        <Link
          style={tw('text-primary')}
          href={`${organization.slug}.resimplifi.com/terms-of-service`}>
          Terms of Service
        </Link>{' '}
        Agreement and the Digital Millennium Copyright Act Policy. We respect
        the intellectual property rights of others. Please visit the{' '}
        <Link
          style={tw('text-primary')}
          href={`${organization.slug}.resimplifi.com/dmca`}>
          DMCA
        </Link>{' '}
        regarding any photographs or other materials posted that may need to be
        removed or taken down or otherwise are in violation of these policies.
      </Text>
      <Text
        fixed
        style={tw('absolute bottom-8 left-8 right-8 text-center text-sm')}>
        Copyright {new Date().getFullYear()} Resimplifi, Inc. All rights
        reserved.
      </Text>
    </Page>
  )
}
