import { Page } from '@react-pdf/renderer'
import { tw } from '~/components/pdfs'
import { Listing } from '~/models'
import { PdfLeaseSpaces, PdfLeaseTransactions } from '../components'

export default function SpacesTransactions({ listing }: { listing: Listing }) {
  return (
    <Page size="LETTER" style={tw('p-8 font-sans')}>
      <PdfLeaseSpaces listing={listing} />
      <PdfLeaseTransactions listing={listing} />
    </Page>
  )
}
