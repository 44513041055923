import { Text, View } from '@react-pdf/renderer'
import { tw } from '~/components/pdfs'
import { getTransactionTableData, Listing } from '~/models'
import { cn } from '~/utils'

export default function PdfLeaseTransactions({
  listing,
}: {
  listing: Listing
}) {
  if ((listing.transactions || []).length === 0) {
    return null
  }

  // No memo for node render
  const { headers, rows } = getTransactionTableData(
    listing.transactions!,
    listing.type === 'sale' ? 'sale' : 'lease'
  ) // sale/lease?

  return (
    <View break={false} style={tw('mt-8')}>
      <Text style={tw('font-heading mb-4 text-base leading-none')}>
        Transacted Spaces
      </Text>
      <View style={tw('flex flex-row')}>
        {headers.map((header, index) => (
          <View key={header.key} style={index === 0 ? tw('flex-1') : undefined}>
            <View
              style={tw(
                cn(
                  'border-b border-slate-200 pb-1',
                  index < headers.length - 1 && 'pr-4'
                )
              )}>
              <Text style={tw('font-sans text-base font-medium')}>
                {header.label}
              </Text>
            </View>
            {listing.transactions!.map(({ _id }) => {
              if (typeof rows[_id] === 'undefined') {
                // not available
                return null
              }
              return (
                <View key={_id} style={tw('py-1 pr-4')}>
                  <Text
                    style={tw(
                      cn(
                        'font-sans text-sm'
                        // , index === 0 && 'font-medium'
                      )
                    )}>
                    {rows[_id][header.key]}
                  </Text>
                </View>
              )
            })}
          </View>
        ))}
      </View>
    </View>
  )
}
