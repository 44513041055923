import { Image, Page, Text, View } from '@react-pdf/renderer'
import { tw } from '~/components/pdfs'
import { type Listing } from '~/models'
import { transformImageUrl } from '~/utils'

export default function PdfImagesPage({ listing }: { listing: Listing }) {
  if ((listing.images || []).length < 2) {
    return null
  }

  return (
    <Page size="LETTER" style={tw('p-5 font-sans')}>
      <Text style={tw('p-3 text-lg font-heading leading-none')}>
        Additional Images
      </Text>
      <View style={tw('flex flex-row flex-wrap')}>
        {listing.images!.slice(1).map((image) => {
          return (
            <Image
              key={image._id}
              style={tw('p-3 w-1/2 h-[172px]')}
              src={transformImageUrl(image.thumbUrl)}
            />
          )
        })}
      </View>
    </Page>
  )
}
