import { Document, Image, Page, Text, View } from '@react-pdf/renderer'
import { PdfBadge, tw } from '~/components/pdfs'
import {
  getListingTypeLabel,
  type Company,
  type Listing,
  type Organization,
  type ReportPdfOptions,
  type User,
} from '~/models'
import {
  STATUSES,
  cn,
  fmtAddress,
  getMaxSubstringLength,
  getPropertyTypeClassName,
  transformImageUrl,
} from '~/utils'
import { PdfListingMeta } from './components'
import {
  PdfDisclaimersPage,
  ReportPdfMapPage,
  ReportPdfTitlePage,
} from './pages'

export default function ReportSummaryPdf({
  organization,
  title,
  user,
  company,
  listings,
  pdfOptions,
}: {
  organization: Organization
  title: string
  user: User
  company?: Company
  listings: Listing[]
  pdfOptions: ReportPdfOptions
}) {
  return (
    <Document title={title}>
      {pdfOptions.showTitlePage && (
        <ReportPdfTitlePage
          title={title}
          company={company}
          user={user}
          listings={listings}
          pdfOptions={pdfOptions}
          orientation="landscape"
        />
      )}
      {pdfOptions.showMapPage && (
        <ReportPdfMapPage
          listings={listings}
          pdfOptions={pdfOptions}
          orientation="landscape"
        />
      )}
      <Page size="LETTER" style={tw('p-8 font-sans')} orientation="landscape">
        <View style={tw('flex flex-col gap-6')}>
          {listings.map((listing, index) => {
            const name = listing.name.substring(
              0,
              getMaxSubstringLength(listing.name, 40)
            )
            return (
              <View key={listing._id} break={false}>
                <View style={tw('flex flex-row gap-6')}>
                  <View style={tw('w-3/12')}>
                    {(listing.images || []).length > 0 ? (
                      <View
                        style={tw(
                          'relative h-[160px] overflow-hidden rounded-sm'
                        )}>
                        <Image
                          style={tw(
                            'absolute bottom-0 left-[-60px] right-[-60px] top-0'
                          )}
                          src={transformImageUrl(listing.images![0].thumbUrl)}
                        />
                        <View
                          style={tw(
                            'absolute left-0.5 top-0.5 flex h-6 min-w-[1.5rem] flex-row items-center justify-center rounded-sm bg-white px-1'
                          )}>
                          <Text style={tw('pb-1 text-xs leading-none')}>
                            {index + 1}
                          </Text>
                        </View>
                      </View>
                    ) : (
                      <View style={tw('h-[160px] rounded-sm bg-gray-200')} />
                    )}
                  </View>
                  <View style={tw('w-9/12')}>
                    {/* <View style={tw('mb-2 flex flex-row items-center gap-4')}> */}
                    <View style={tw('mb-1')}>
                      <View
                        style={tw(
                          'mb-1 flex w-full flex-row items-center justify-between gap-6'
                        )}>
                        <View style={tw('flex flex-row items-start gap-2')}>
                          <PdfBadge
                            className={cn(
                              'mt-px text-white',
                              listing.type === 'sale' &&
                                'bg-[#0284c7] hover:bg-[#0284c7]',
                              listing.type === 'lease' &&
                                'bg-[#c026d3] hover:bg-[#c026d3]',
                              listing.type === 'saleLease' &&
                                'bg-[#4f46e5] hover:bg-[#4f46e5]'
                            )}>
                            {STATUSES[listing.status]}
                          </PdfBadge>
                          <Text
                            style={tw('font-heading text-xl leading-tight')}>
                            {name.length < listing.name.length
                              ? `${name}...`
                              : name}
                          </Text>
                        </View>

                        <View style={tw('flex flex-row items-center gap-4')}>
                          <Text style={tw('font-heading text-lg leading-none')}>
                            {getListingTypeLabel(listing)}
                          </Text>
                          <View style={tw('flex flex-row gap-1')}>
                            {listing.propertyTypes
                              .slice(0, 1)
                              .map((value, index) => {
                                return (
                                  <PdfBadge
                                    key={index}
                                    className={cn(
                                      'text-white',
                                      getPropertyTypeClassName(value)
                                    )}>
                                    {value}
                                  </PdfBadge>
                                )
                              })}
                          </View>
                        </View>
                      </View>
                      <View
                        style={tw(
                          'flex w-full flex-row justify-between gap-6'
                        )}>
                        <Text style={tw('font-sans text-base text-muted')}>
                          {fmtAddress(listing.property, true)}
                        </Text>
                        {(listing.propertySubtypes || []).length > 0 && (
                          <Text style={tw('mb-3 font-sans text-sm italic')}>
                            Secondary Uses:{' '}
                            {listing.propertySubtypes!.slice(0, 4).join(', ')}
                          </Text>
                        )}
                      </View>
                    </View>
                    <PdfListingMeta listing={listing} pdfOptions={pdfOptions} />
                  </View>
                </View>
              </View>
            )
          })}
        </View>
      </Page>
      <PdfDisclaimersPage
        organization={organization}
        pdfOptions={pdfOptions}
        orientation="landscape"
      />
    </Document>
  )
}
