import { Page, Text, View } from '@react-pdf/renderer'
import { tw } from '~/components/pdfs'
import { Listing, ParcelData } from '~/models'
import { getParcelLabelValues } from '~/utils'

export default function ReportPdfParcelPage({
  listing,
  parcels,
}: {
  listing: Listing
  parcels: ParcelData[]
}) {
  const parcel = parcels.find(
    (parcel) => parcel.parcel_id === listing.property.parcelApn
  )
  if (!parcel) {
    return null
  }
  return (
    <Page size="LETTER" style={tw('p-8 font-sans')}>
      <Text style={tw('font-heading mb-4 text-xl font-semibold')}>
        Parcel Data
      </Text>
      <View style={tw('flex flex-col gap-2 text-sm')}>
        {getParcelLabelValues(parcel).map(({ key, label, value }) => {
          return (
            <View key={key} style={tw('flex flex-row gap-8')}>
              <Text style={tw('w-1/5 font-medium leading-tight')}>
                {label}:
              </Text>
              <Text style={tw('w-4/5')}>{value}</Text>
            </View>
          )
        })}
      </View>
    </Page>
  )
}
