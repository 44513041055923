import { LucideFiles, LucideLayoutList, LucideTable2 } from 'lucide-react'
import { useControlField, useFormContext } from 'remix-validated-form'
import { CheckboxField, InfoTooltip } from '~/components'
import { PdfNotesField, PdfPreparedForField } from '~/components/pdfs'
import { ToggleGroup } from '~/components/ui'
import { type Report } from '~/models'
import { cn } from '~/utils'

export default function PdfFormContent({
  formId,
  report,
  singleListing = false,
}: {
  formId: string
  report?: Report
  singleListing?: boolean
}) {
  const formContext = useFormContext(formId)
  const [type, setType] = useControlField<string>('options.type', formId)
  const [showTitlePage] = useControlField<boolean>(
    'options.showTitlePage',
    formId
  )

  return (
    <>
      <p className="col-span-12 text-sm italic">
        Note: Form changes in preview may take longer to refresh depending on
        the number of listings
      </p>
      <div className={cn('col-span-12', singleListing && 'hidden')}>
        <p className="mb-3 font-medium">
          Report Type
          <InfoTooltip className="ml-2">
            <strong>Flyer PDF</strong>
            <p className="mb-2">
              One listing per page with options to include additional elements
            </p>
            <strong>Summary PDF</strong>
            <p className="mb-2">
              Multiple listings per page with only listing attributes
            </p>
            <strong>Comparison PDF</strong>
            <p>Compare four listings side by side per page</p>
          </InfoTooltip>
        </p>
        <input type="hidden" name="options.type" value={type} />
        <ToggleGroup
          type="single"
          className="justify-start"
          value={type}
          onValueChange={(type) => {
            if (type) setType(type)
          }}
          disabled={formContext.isSubmitting}>
          <ToggleGroup.Item
            value="flyers"
            className="flex text-center text-foreground">
            <LucideFiles className="h-4 w-4" />
            <p className="px-2 text-foreground">Flyers</p>
          </ToggleGroup.Item>
          <ToggleGroup.Item value="summary">
            <LucideLayoutList className="h-4 w-4" />
            <p className="px-2 text-foreground">Summary</p>
          </ToggleGroup.Item>
          <ToggleGroup.Item value="comparison">
            <LucideTable2 className="h-4 w-4" />
            <p className="px-2 text-foreground">Comparison</p>
          </ToggleGroup.Item>
        </ToggleGroup>
      </div>
      <p className="col-span-12 -mb-2 mt-1 font-medium">
        Title Page
        <InfoTooltip className="ml-2">
          <p>
            Introductory page to PDF Report with space for recipient, notes,
            user profile and table of contents
          </p>
        </InfoTooltip>
      </p>
      <CheckboxField
        className="col-span-12"
        label="Show Title Page"
        name="options.showTitlePage"
        disabled={formContext.isSubmitting}
      />
      {showTitlePage && (
        <>
          <CheckboxField
            className="col-span-12"
            label="Show User Profile"
            name="options.showBrokerProfile"
            disabled={formContext.isSubmitting}
          />
          <CheckboxField
            className={cn('col-span-12', singleListing && 'hidden')}
            label="Show Table of Contents"
            name="options.showContents"
            disabled={formContext.isSubmitting}
          />
          <PdfPreparedForField
            formId={formId}
            name="options.preparedFor"
            formContext={formContext}
            initialValue={report?.pdf?.options?.preparedFor}
          />
          <PdfNotesField
            formId={formId}
            name="options.notes"
            formContext={formContext}
            initialValue={
              report?.pdf?.options?.notes || report?.description
                ? (report.pdf?.options?.notes || report.description || '')
                    .split('</p>')
                    .map((v) => v.replace('<p>', ''))
                    .join('\n\n')
                    .trim()
                : ''
            }
          />
        </>
      )}
      <p className="col-span-12 -mb-2 mt-1 font-medium">
        Map Page
        <InfoTooltip className="ml-2">
          <p>Include numbered listing markers of all listings included</p>
        </InfoTooltip>
      </p>
      <CheckboxField
        className="col-span-12 col-start-1"
        label="Show Map Page"
        name="options.showMapPage"
        disabled={formContext.isSubmitting}
      />
      {type === 'flyers' && (
        <>
          <p className="col-span-12 -mb-2 mt-1 font-medium">
            Listings
            <InfoTooltip className="ml-2">
              <p>
                Optionally show additional listing information on separate pages
              </p>
            </InfoTooltip>
          </p>
          <CheckboxField
            className="col-span-12"
            label="Show Brokers on Listings"
            name="options.showBrokers"
            disabled={formContext.isSubmitting}
          />
          <CheckboxField
            className="col-span-12"
            label="Show Separate Spaces and Transactions"
            name="options.showLeaseSpaces"
            disabled={formContext.isSubmitting}
          />
          <CheckboxField
            className="col-span-12"
            label="Show Additional Listing Images"
            name="options.showImages"
            disabled={formContext.isSubmitting}
          />
          <CheckboxField
            className="col-span-12"
            label="Show Parcel Data"
            name="options.showParcels"
            disabled={formContext.isSubmitting}
          />
        </>
      )}
    </>
  )
}
