import { Document } from '@react-pdf/renderer'
import type {
  Company,
  Listing,
  Organization,
  ReportPdfOptions,
  User,
} from '~/models'
import {
  PdfDisclaimersPage,
  ReportPdfMapPage,
  ReportPdfTitlePage,
} from './pages'
import PdfListingTablePage from './pages/ListingTable'

export default function ReportComparisonPdf({
  organization,
  title,
  user,
  company,
  listings,
  pdfOptions,
}: {
  organization: Organization
  title: string
  user: User
  company?: Company
  listings: Listing[]
  pdfOptions: ReportPdfOptions
}) {
  return (
    <Document title={title}>
      {pdfOptions.showTitlePage && (
        <ReportPdfTitlePage
          title={title}
          company={company}
          user={user}
          listings={listings}
          pdfOptions={pdfOptions}
          orientation="portrait"
        />
      )}
      {pdfOptions.showMapPage && (
        <ReportPdfMapPage
          listings={listings}
          pdfOptions={pdfOptions}
          orientation="portrait"
        />
      )}
      <PdfListingTablePage listings={listings} />
      <PdfDisclaimersPage
        organization={organization}
        pdfOptions={pdfOptions}
        orientation="portrait"
      />
    </Document>
  )
}
