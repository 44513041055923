import { LucideInfo } from 'lucide-react'
import React from 'react'
import { Button, Tooltip } from '~/components/ui'

export default function InfoTooltip({
  className,
  children,
  side = 'left',
  align = 'center',
}: {
  className?: string
  children: React.ReactNode
  side?: 'left' | 'right' | 'top' | 'bottom'
  align?: 'start' | 'center' | 'end'
}) {
  return (
    <Tooltip.Provider delayDuration={200}>
      <Tooltip>
        <Tooltip.Trigger asChild>
          <Button variant="ghost" size="none" className={className}>
            <LucideInfo className="h-4 w-4" />
          </Button>
        </Tooltip.Trigger>
        <Tooltip.Content side={side} align={align}>
          <div className="max-w-sm">{children}</div>
        </Tooltip.Content>
      </Tooltip>
    </Tooltip.Provider>
  )
}
