import type {
  Company,
  Listing,
  Organization,
  ParcelData,
  ReportPdfOptions,
  User,
} from '~/models'
import ReportComparisonPdf from './Comparison'
import ReportFlyersPdf from './Flyers'
import ReportSummaryPdf from './Summary'

export type ReportPdfSwitchProps = {
  organization: Organization
  title: string
  user: User
  company?: Company
  listings: Listing[]
  parcels: ParcelData[]
  pdfOptions: ReportPdfOptions
}

export default function ReportPdfSwitch({ ...props }: ReportPdfSwitchProps) {
  if (props.pdfOptions.type === 'flyers') {
    return <ReportFlyersPdf {...props} />
  } else if (props.pdfOptions.type === 'summary') {
    return <ReportSummaryPdf {...props} />
  } else if (props.pdfOptions.type === 'comparison') {
    return <ReportComparisonPdf {...props} />
  }
  return null
}
