import { Text, View } from '@react-pdf/renderer'
import { tw } from '~/components/pdfs'
import {
  getReportMetaLabelValues,
  type Listing,
  type ReportPdfOptions,
} from '~/models'

export default function PdfListingMeta({
  listing,
  pdfOptions,
}: {
  listing: Listing
  pdfOptions: ReportPdfOptions
}) {
  const listingMeta = getReportMetaLabelValues(listing)

  if (pdfOptions.type === 'flyers') {
    return (
      <View>
        {listingMeta.map(({ key, label, value }) => {
          return (
            <View key={key} style={tw('mb-1 flex flex-row')}>
              <View style={tw('w-1/2')}>
                <Text style={tw('font-sans text-sm text-muted')}>{label}</Text>
              </View>
              <View style={tw('w-1/2')}>
                <Text style={tw('text-right font-sans text-sm font-medium')}>
                  {value}
                </Text>
              </View>
            </View>
          )
        })}
      </View>
    )
  } else if (pdfOptions.type === 'summary') {
    const partLength = Math.ceil(listingMeta.length / 5)
    const parts = [
      listingMeta.slice(0, partLength),
      listingMeta.slice(partLength, 2 * partLength),
      listingMeta.slice(2 * partLength, 3 * partLength),
      listingMeta.slice(3 * partLength, 4 * partLength),
      listingMeta.slice(4 * partLength),
    ]

    return (
      <View style={tw('flex flex-row gap-6')}>
        {parts.map((part, index) => (
          <View key={index} style={tw('w-1/5')}>
            {part.map(({ key, value, label }) => {
              return (
                <View key={key} style={tw('mb-1')}>
                  <Text style={tw('font-sans text-sm text-muted')}>
                    {label}
                  </Text>
                  <Text style={tw('font-sans text-sm font-medium')}>
                    {value}
                  </Text>
                </View>
              )
            })}
          </View>
        ))}
      </View>
    )
  }
  return null
}
